import {atom, selector} from "recoil";
import {getClaimAmount} from "../utils";

const addressState = atom({
  key: 'addressState', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});

const claimAmount = selector({
  key: 'claimAmount',
  get: ({get}) => {
    const address = get(addressState)
    const amount = getClaimAmount(address)
    console.log(amount)
    return {
      amount
    }
  }
})

export {
  addressState,
  claimAmount
}
