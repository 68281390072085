import {s5Bg} from "../assets/images";
import React, {useState} from "react";
import {Button} from "./Button";
import {jumpToBuy, getClaimAmount, getProof} from "../utils";
import Wallet from "../utils/wallet";
import {useRecoilState, useRecoilValue} from "recoil";
import {addressState, claimAmount} from "../store";

export const Section5 = () => {
	const wallet = new Wallet.Wallet()

	const [address, setAddress] = useRecoilState(addressState)

	const {amount} = useRecoilValue(claimAmount)
	async function connect() {
		await wallet.getConnect();
	}
	async function claim() {
		const claimNumber = getClaimAmount(address)
		const proof = getProof(address.toLowerCase())
		console.log(claimNumber, proof)
		await wallet.claimCoin(claimNumber, proof)
	}
	const weight = [
		'MDOG - 0.5',
		'DOGC - 1',
		'DOGC ending in 0 holder - 1.5',
		'DOGC golden skin - 2',
		'MEGA - Including unrevealed - 3'
	]
	const btn1Prop = {
		text: String(amount),
		bgColor: '#F3F3F3',
		width: 287,
		onClick: () => {}
	}
	const btn2Prop = {
		text: 'claim',
		width: 155,
		onClick: claim
	}
	const buttonProps = {
		onClick: connect,
		width: 196,
		fontSize: 16,
		text: 'Connect Wallet'
	}
	return (
		<div
			className='relative text-[#0D0D0D] font-medium py-[149px]'>
			<img className='absolute w-[825px] bottom-0 ml-[-760px] left-1/2' src={s5Bg} alt=""/>
			<div className='w-[1140px] mx-auto pl-[600px]'>
				<p className='font-black text-title leading-[78px] mb-[20px] text-black'>Airdrop</p>
				<p className='text-16px font-mont-bold leading-[20px] mb-[10px] text-[#333333]'>community allocation</p>
				<p className='text-16px mb-[30px] leading-[19px] opacity-80'>
					6% of the tokens will be allocated to 2,000 Doge Club NFT holders<br/>
					who have been building this community for about a year.<br/>
					The claiming period is two weeks. Unclaimed portions will be put<br/>
					into a multi-signature wallet for use.
				</p>
				<p className='text-16px font-mont-bold leading-[20px] mb-[15px] text-[#333333]'>Snapshot distribution weight</p>
				<ul className='mb-[43px] opacity-80 text-16px leading-[20px]'>
					{weight.map((item, index) => (
						<li key={index}>{item}</li>
					))}
				</ul>
				<div className='flex px-[3px] w-[462px] justify-between'>
					{address
						? (
							<>
								<Button {...btn1Prop}></Button>
								{/*<Button {...btn2Prop}></Button>*/}
							</>
						)
						:	<Button {...buttonProps}></Button>}
				</div>
			</div>
		</div>
	)
}

