import {Header} from "./Header";
import {Button} from "./Button";
import React from "react";
import {jumpToBuy} from "../utils";
import {s1DT, s1TW, s1TG, s1Bg, s1US, s1ES, s1CM} from '../assets/images/index'
export const Section1 = () => {
	const buttonProps = {
		text: 'Buy Now',
		onClick: jumpToBuy
	}
	const links = [{
		name: 'twitter',
		icon: s1TW,
		url: 'https://twitter.com/DogeClub_NFT'
	}, {
		name: 'telegram',
		icon: s1TG,
		url:  'https://t.me/DogeClub_MemeParty'
	}, {
		name: 'dextool',
		icon: s1DT,
		url: 'https://www.dextools.io/app/ether/pair-explorer/0x22453fb15757f2a8177880d8b8cf5144f07d1679'
	}, {
		name: 'etherscan',
		icon: s1ES,
		url: 'https://etherscan.io/token/0xda8263d8ce3F726233f8b5585bcb86a3120a58B6#readContract'
	}, {
		name: 'uniswap',
		icon: s1US,
		url: 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xda8263d8ce3F726233f8b5585bcb86a3120a58B6'
	}]
	const linkDom =
		<div className="flex mt-[83px]">
			{links.map((link) => (
				<div
					key={link.name}
					className='mr-[28px] w-[60px] h-[60px] bg-contain'
					style={{backgroundImage: `url(${link.icon})`}}
				>
					<a
						className='block w-full h-full'
						href={link.url}
						target='_blank'
						rel="noreferrer"></a>
				</div>
			))}
		</div>

	return (
		<div className='relative bg-gradient-to-b from-[#103A65] to-[#63B4FF] pb-[403px] w-full overflow-hidden'>
			<img className='absolute w-[1098px] ml-[-140px] left-1/2' src={s1Bg} alt=""/>
			<Header />
			<div className="w-[1140px] font-mont-sb text-white mx-auto pt-[150px]">
				<p className='leading-[154px] font-mont-black text-[150px] mb-[30px]'>$DOGC</p>
				<p className='leading-[29px] mb-[30px] text-[24px]'>Welcome to DogeClub</p>
				<p className='leading-[44px] mb-[63px] text-[36px]'>Doge MEME is eternal</p>
				<Button {...buttonProps}></Button>
				{linkDom}
			</div>
		</div>
	)
}
