import {addressAmount} from "./AddressAmount";
import {ethers} from 'ethers'

type Address = keyof typeof addressAmount
let merkleTree:any
function generateTree() {
  const leaves = []
  for (const item in addressAmount) {
    leaves.push(
      ethers.solidityPacked(['address', 'uint256'], [item, addressAmount[item as Address]])
    )
  }

  const leafNodes = leaves.map(content => window.keccak256(content))
  merkleTree = new window.MerkleTree(leafNodes, window.keccak256, {sortPairs: true})
  console.log(merkleTree.getRoot().toString('hex'))
}
function getProof(address: Address|string) {
  if (addressAmount[address as Address] > 0) {
    const node = ethers.solidityPacked(['address', 'uint256'], [address, addressAmount[address as Address]])
    return (merkleTree as any).getHexProof(window.keccak256(node));
  } else {
    return null
  }
}

function getClaimAmount(address: Address | string) {
  console.log(address, addressAmount[address.toLowerCase() as Address])
  return addressAmount[address.toLowerCase() as Address] || 0
}

function jumpToBuy() {
  const contractAddress = '0xda8263d8ce3F726233f8b5585bcb86a3120a58B6'
  const uniswapUrl = `https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${contractAddress}`

  window.open(uniswapUrl, '_blank')
}

export {
  generateTree,
  getProof,
  getClaimAmount,
  jumpToBuy
}
