import {ethers} from 'ethers'
import ClaimAbi from './Claim'
import CoinAbi from './DogcCoin'
import config from './config'
import {addressState} from "../store";
import { getRecoil, setRecoil} from 'recoil-nexus'
import claim from "./Claim";


const claimABIStr = JSON.stringify(ClaimAbi.abi)
const coinABIStr = JSON.stringify(CoinAbi.abi)


const claimAddress = '0xefDbaC29E8e0CC7389C3ff342266105858077b42'
const coinAddress = '0x9Cd6274CC1688C4415318CBb4623F01F47DE5e61'


let providers:any;

// const EtherNetwork = 'homestead';
// const EtherNetwork = 1337;
const EtherNetwork = 'goerli';


const Ether:any = { };
// const p = new ethers.providers.InfuraProvider(EtherNetwork, '9e92c574cb904603932c65a1ca12da66');
// const p = new ethers.providers.JsonRpcProvider('http://127.0.0.1:8545/')

let claimContract: ethers.Contract;
let coinContract: ethers.Contract;


let provider: ethers.BrowserProvider
let signer: ethers.Signer

async function reloadContract(signer: ethers.Signer) {
  claimContract = new ethers.Contract(claimAddress, claimABIStr, signer)
  coinContract = new ethers.Contract(coinAddress, coinABIStr, signer)
}

Ether.Wallet = function () {
  this.Init = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.ethereum
    } catch (error) {
      console.error('No Ethereum')
    }
    const active = localStorage.getItem('isLogin')
    if (window.ethereum && active === 'true') {
      provider = new ethers.BrowserProvider(window.ethereum, EtherNetwork)
      signer = await provider.getSigner()
      await reloadContract(signer)
      const address = await signer.getAddress()
      setRecoil(addressState, address)
    }
  }
  // The function of connecting to the wallet
  // 1. connect to wallet
  // 2. get the address of the wallet
  // 3. get the nonce from server
  // 4. sign nonce by wallet
  // 5. send the signature to login
  this.getConnect = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.ethereum
    } catch (error) {
      console.error('No Ethereum')
    }
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
      } catch (error) {
        console.error('User denied account')
      }
      provider = new ethers.BrowserProvider(window.ethereum, EtherNetwork)
      signer = await provider.getSigner()
      const a = await signer.getAddress()
      setRecoil(addressState, a)
      console.log(a)
      await reloadContract(signer)
      localStorage.setItem('isLogin', 'true')
      return await signer.getAddress()
    } else {
      console.log('no plugin installed')
      return 'No Ethereum'
    }
  }

  this.connectByScan = async () => {
    providers = new window.WalletConnectProvider.default({
      infuraId: config.infura_key
    })
    await providers.disconnect()
    try {
      const accounts = await providers.enable()
      const web3Provider = new ethers.BrowserProvider(providers, EtherNetwork)
      signer = await web3Provider.getSigner();
      const a = await signer.getAddress()
      setRecoil(addressState, a)
      await reloadContract(signer)
      localStorage.setItem('isLogin', 'true')
      return accounts[0]
    } catch (err) {
      console.log(err)
    }
  }

  this.disconnect = async () => {
    localStorage.setItem('isLogin', 'false')
    await providers?.disconnect()
  }

  this.getBalance = async () => {
    const balance = await claimContract.balance()
    console.log(balance)
  }

  this.claimCoin = async (amount: number, merkleProof:any) => {
    console.log(amount, merkleProof)
    const tx = await claimContract.claim(amount, merkleProof)
    await tx.wait()
  }

  this.getCoinBalance = async () => {
    const address = getRecoil(addressState)
    const balance = await coinContract.balanceOf(address)
    console.log(balance)
  }

  this.checkClaimed = async () => {
    const address = getRecoil(addressState)
    const result = await claimContract.claimed(address)
    console.log(result)
  }

  this.changeSnapshot = async ()=> {
    await claimContract.changeSnapshot('0xcb04f07d3bf6f7a0d1631b23c32a5004f39e89fe7cd4fbde6fa1604f362dedff')
  }


}

export default Ether
